body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
}

.logoMobil {
  width: 100%;
  max-width: 150px;
}

a {
  cursor: pointer
}

@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'), url(./fonts/HelveticaNowText-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'HelveticaBold';
  src: local('HelveticaBold'), url(./fonts/HelveticaNowText-Bold.ttf) format('truetype');
}

:root {
  --bgImage: url("https://totalaplicaciones.com/emailimages/toolsprovweb/img/background.png");
  --bgImage-white: url("https://simplitpos.com/img/SimplitPOS_1White.png");
  --main-bg-color0: #FFFFFF;
  --main-bg-color1: #2b334a;
  --main-bg-color2: #ece8e8;
  --main-bg-color3: #44c0ec;
  --main-bg-color4: #3679E9;
  --main-bg-color5: #679635;
  --main-bg-color6: rgb(247, 224, 216);
  --main-bg-color7: #F15A25;
  --main-bg-color8: #868687;
  --main-bg-color9: #F50A00;
  --main-bg-color10: rgba(0, 0, 0, 0);
  --main-bg-color11: rgba(134, 134, 135, 0.5);
  --main-list-color0: #e1dddc;
  --main-list-color1: #e9e9e9;
  --main-list-color2: #848181;
  --main-hover-color0: #44c0ec;
  --main-hover-color1: #777475;
  --main-title-color0: #2d2d2d;
  --main-text-color0: #2b334a;
  --main-text-color1: #FFFFFF;
  --main-text-color2: #44c0ec;
  --main-text-color3: #444443;
  --main-table-color0: #31B700;
  --main-table-color1: #44c0ec;
  --main-table-color2: #F50A00;
  --main-table-color4: #44c0ec;
  --main-table-color3: #848181;
  --main-bg-ping: #ED5500;
  --main-indicator-color1:#677bbb;
  --main-indicator-color2:#a33054;
  --main-indicator-color3:#d57445;
  --main-indicator-color4:#c6a549;
  --main-chart-color1: #0258B6;
  --main-chart-color2: #3F4888;
  --main-chart-color3: #7C385B;
  --main-chart-color4: #B9282D;
  --main-chart-color4: #F71900;
}